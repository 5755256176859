/**
 * @file_purpose  page for footer component
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */
import React, { useEffect, useState } from 'react'
import Logo from '../../asset/image/logo.png'
import { Link,useLocation } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { HashLink, NavHashLink } from 'react-router-hash-link';

const Footer = () => {

  const [show, setShow] = useState(localStorage.getItem("Over18") == "Yes" ? false : true);

  useEffect(()=>{    window.scrollTo(0, 0)},[])

  const handleClose = () => {
    localStorage.setItem("Over18","Yes");
    setShow(false)
  };
  const handleShow = () => setShow(true);
  const location = useLocation();
  return (
  <footer className="footer_sec">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-4 text-center text-md-center text-lg-start">
          <img src={Logo} alt="Bets2Win" />
          <ul className='social-link'>
            <li><Link to="https://www.facebook.com"><i class="fa-brands fa-facebook-f"></i></Link></li>
            <li><Link to="https://www.instagram.com"><i class="fa-brands fa-instagram"></i></Link></li>
            <li><Link to="https://www.twitter.com"><i class="fa-brands fa-x-twitter"></i></Link></li>
            <li><Link to="https://www.linkdin.in"><i class="fa-brands fa-linkedin-in"></i></Link></li>
            <li><Link to="https://www.youtube.com"><i class="fa-brands fa-youtube"></i></Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-lg-8 my-md-3 my-lg-0">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <ul className='footer-menu'>
                    <li><Link to="/our-game/all-tab">OUR GAMES</Link></li>
                    <li><Link to="/poker">POKER SOLUTIONS</Link></li>
                    <li><Link to="/about">ABOUT US</Link></li>
                  </ul>
                </div>
                <div className="col-12 col-sm-6">
                  <ul className='footer-menu'>
                    {/* <li><Link to="">CLIENT HUB</Link></li> */}
                    <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
                    <li><Link to="/terms-of-service">TERMS OF SERVICES</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <h4 className='footer-heading'>CONTACT US FOR MORE INFORMATION.</h4>
              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="Enter your e-mail Id" aria-label="Enter your e-mail Id" aria-describedby="basic-addon2"/>
                <button className="input-group-text footer-btn" id="basic-addon2">Send</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 text-center copyright">
          <p className='m-0 py-3'>© 2024 bets2win.com. All Rights Reserved.</p>
        </div>
      </div>
    </div>

    {/* MODAL */}
    {/* <Button variant="primary" onClick={handleShow}>
      Launch static backdrop modal
    </Button> */}
    <Modal
        size= "lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="ageVerifyModal"
      >
        
        <Modal.Body>
          <div className="row">
            <div className="col-12 p-5 pt-0">
              <p className='text-center'>
              The content provided by bets2win is designed for individuals aged 18 years or older. Kindly verify that you meet the legal age requirement to engage with gambling products in your jurisdiction.
              </p>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center text-center">
              <div>
              <button className='custom-btn animate-btn mb-5' onClick={handleClose}>Yes, I am Over 18 Years</button><br/>
              <button className='btn'>No, Take Me Back</button>
              </div>
              
            </div>
          </div>
        </Modal.Body>
      </Modal>




  </footer>
  )
}

export default Footer
